.admin {
  .rules{
    .form-check-label{
        margin: 0 1.5rem;
    }
}
}
@media only screen and (max-width: 500px) {
  .admin{
    .card-body{
      display: block!important;

      .input-group{
        width: 100%!important;
      }

      .add-admin{
        margin-top: 12px;
      }
    }
    .table td {
      text-wrap: nowrap;
    }
  }
  .en .add-admin{
    text-align: right;
  }
  .ar .add-admin{
    text-align: left;
  }
  .ar .rules .form-check-label {
    margin: 0 1.5rem;
  }
  .en .rules .form-check-label {
    margin: 0 .5rem;
  }
}