.emp-profile{
    .image-placeholder {
        max-width: max-content;
        margin: 0;
    }
    .image-placeholder .avatar-edit {
        position: absolute;
        right: -6px;
    }
    .la-user{
        font-size: 6rem;
        border: 2px solid;
        border-radius: 50%;
        padding: 4px;
    }
    .avatar{
        width: 108px;
        height: 108px;
        border: 2px solid;
        border-radius: 50%;
        padding: 4px;
    }
    li{
        display: flex;
    }
    .basic{
        display: flex;
        gap: 30px;
        .job-title,
        .department{
            line-height: 1;
        }
        .date-join{
            font-size: 14px;
        }
    }
    .personal-info{
        .title {
            color: #373b3e;
            font-weight: 500;
            margin-bottom: 8px;
        }
        .text {
            color: #ff902f;
            display: block;
            overflow: hidden;
            margin-bottom: 8px;
        }
    }
}
.en .basic{
    border-right: 2px dotted #dedede;
}
.en .title {
    width: 40%;
}
.en .text {
    width: 60%;
}
.ar .title {
    width: 40%;
}
.ar .text {
    width: 60%;
}
// profile
.emp-profile{
    .profile{
        .edit-icon{
            margin: 0 1rem;
        }
        .personal-info{
            margin-top: 2rem;
        }
        .text{
            color: #7a7c7f;
        }
    }
}