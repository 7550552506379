.rules{
    .form-check-label{
        margin: 0 1.5rem;
    }
    .rule-section{
        padding: 1rem;
        border: 1px solid #dedede;
        border-radius: 8px;
        height: 100%;
    }
}
@media only screen and (max-width: 500px) {
    .rules{
        .form-row{
            .form-group{
                width: 100% !important;
            }
        }
    }
    .permmision-buttons{
        gap: 6px!important;
        button{
            padding: .5rem 1rem;
        }
    }
}