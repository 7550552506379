.custom-tabs-div{
    padding-right: 1.2rem;
    padding-left: 1.2rem;
    display: flex;
    gap: 16px;
    flex-flow: wrap;
}
.custom-tabs-div p{
    border: 2px solid #dedede;
    padding: 4px 10px;
    cursor: pointer;
    border-radius: 4px;
    margin: 0;
    display: flex;
    align-items: center;  
    gap: 8px;  
    font-weight: 500;
}
.custom-tabs-div p.activeP{
    background-color: var(--primary);
    color: #fff;
    border: 2px solid var(--primary);
}
/* .custom-tabs-div span{
    height: 6px;
    width: 6px;
    background: #dedede;
    border-radius: 50%;
}
.custom-tabs-div p.activeP span{
    background-color: var(--primary);
} */
.tabs-div {
    display: flex;
    gap: 18px;
    width: fit-content;
    margin: auto;
    background: var(--badge-primary);
    padding: 1rem;
    border-radius: 20px;
    align-items: center;
    flex-flow: wrap;
}
.tabs-div p{
    margin: 0;
    color: var(--primary);
    cursor: pointer;
    padding: 4px 7px;
}
.tabs-div .activeP{
    background-color: var(--primary);
    color: #fff;
    border-radius: 10px;
}
thead{
    background-color: #EEF1F0;
}
tbody tr:nth-of-type(even){
    background-color: #F7F9F8;
}
.la-ellipsis-h{
    border: 1px solid var(--primary);
    border-radius: 4px;
    font-size: 22px!important;
    padding: 3px;
}
@media only screen and (max-width: 500px) {
    .custom-tabs-div{
        padding: 0 8px !important;
    }
}